import React, { useState } from 'react';
import { bool, node } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import classNames from 'classnames';

import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import * as validators from '../../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldRadioButton,
  FieldLocationAutocompleteInput,
} from '../../../components';

import css from './SignupForm.module.css';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  whichOneClientOptions,
  whichOneModelOptions,
} from '../../../config/configListing';
import { MODEL } from '../../../util/types';
const identity = v => v;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        values,
        form,
        termsAndConditions,
      } = fieldRenderProps;

      const [phoneNumberState, setPhoneNumberState] = useState(null);
      const [checkedOnce, setCheckedOnce] = useState(false);
      const [valid, setValid] = useState(null);
      // email
      const emailRequired = validators.required(
        intl.formatMessage({
          id: 'SignupForm.emailRequired',
        })
      );
      const emailValid = validators.emailFormatValid(
        intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        })
      );
      const birthDateRequiredMessage = intl.formatMessage({
        id: 'SignupForm.birthDateRequiredMessage',
      });

      const addressRequiredMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressRequired',
      });
      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const phonePlaceholder = intl.formatMessage({
        id: 'SignupForm.phonePlaceholder',
      });
      const phoneLabel = intl.formatMessage({ id: 'SignupForm.phoneLabel' });

      const modelLabel = intl.formatMessage({
        id: 'SignupForm.modelLabel',
      });

      const clientLabel = intl.formatMessage({
        id: 'SignupForm.clientLabel',
      });

      // password
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;
      form.change('phoneNumber', phoneNumberState);
      const checkphoneNumber = form => {
        if (phoneNumberState && !checkedOnce) {
          const formatedNumber = formatPhoneNumberIntl(values.phoneNumber);
          if (isValidPhoneNumber(formatedNumber)) {
            setCheckedOnce(true);
            setValid(true);
          } else {
            setCheckedOnce(true);
            setValid(false);
          }
        }
      };

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div className={css.signupContent}>
            <div className={css.formRowContent}>
              <h4> <FormattedMessage id="SignupForm.userTypeHeadingText" /></h4>

              <div className={css.formRow}>
                <div className={css.formFld}>
                  <FieldRadioButton
                    id={formId ? `${formId}.model` : 'model'}
                    name="userType"
                    label={modelLabel}
                    value="model"
                    required={true}
                  />
                </div>
                <div className={css.formFld}>
                  <FieldRadioButton
                    id={formId ? `${formId}.client` : 'client'}
                    name="userType"
                    label={clientLabel}
                    value="client"
                    required={true}
                  />
                </div>
              </div>
            </div>

            <div className={css.formRowContent}>
              <h4><FormattedMessage id="SignupForm.userTypeSubDetailsText" /></h4>
              <div className={css.rowBox}>
                {values && values?.userType === MODEL ? (
                  <>
                    {whichOneModelOptions.map((item, index) => {
                      return (
                        <div className={css.formFld}>
                          <FieldRadioButton
                            id={formId ? `${formId}.${index}` : index}
                            name="modelType"
                            label={item.label}
                            value={item.key}
                            required={true}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div className={css.clientOption}>
                    {whichOneClientOptions.map((item, index) => {
                      return (
                        <div>
                          <FieldRadioButton
                            id={formId ? `${formId}.${index}` : index}
                            name="clientType"
                            label={item.label}
                            value={item.key}
                            required={true}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>

            <div className={css.formRowContent}>
              <h4 className={css.userDetailsHeading}><FormattedMessage id="SignupForm.userDetais" /></h4>

              {values && values?.userType === MODEL ? (
                <>
                  <div className={css.formRowBox}>
                    <div className={css.formFld}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.fname` : 'fname'}
                        name="fname"
                        autoComplete="given-name"
                        label={intl.formatMessage({
                          id: 'SignupForm.firstNameLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.firstNamePlaceholder',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'SignupForm.firstNameRequired',
                          })
                        )}
                      />
                    </div>
                    <div className={css.formFld}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.lname` : 'lname'}
                        name="lname"
                        autoComplete="family-name"
                        label={intl.formatMessage({
                          id: 'SignupForm.lastNameLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.lastNamePlaceholder',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'SignupForm.lastNameRequired',
                          })
                        )}
                      />
                    </div>
                  </div>
                  <div className={classNames(css.formRow, css.fullWidth)}>
                    <div className={css.formFld}>
                      <FieldTextInput
                        type="email"
                        id={formId ? `${formId}.email` : 'email'}
                        name="email"
                        autoComplete="email"
                        label={intl.formatMessage({
                          id: 'SignupForm.emailLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.emailPlaceholder',
                        })}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                    </div>

                  </div>


                </>
              ) : (
                <>
                  <div className={css.formRowBox}>
                    <div className={css.formFld}>
                      <FieldTextInput
                        // className={css.firstNameRoot}
                        type="text"
                        id={formId ? `${formId}.fname` : 'fname'}
                        name="fname"
                        autoComplete="given-name"
                        label={intl.formatMessage({
                          id: 'SignupForm.firstNameLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.firstNamePlaceholder',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'SignupForm.firstNameRequired',
                          })
                        )}
                      />
                    </div>
                    <div className={css.formFld}>
                      <FieldTextInput
                        // className={css.firstNameRoot}
                        type="text"
                        id={formId ? `${formId}.lname` : 'lname'}
                        name="lname"
                        autoComplete="given-name"
                        label={intl.formatMessage({
                          id: 'SignupForm.lastNameLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.lastNamePlaceholder',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'SignupForm.lastNameRequired',
                          })
                        )}
                      />
                    </div>
                  </div>
                  <div className={classNames(css.formRow, css.fullWidth)}>
                    <div className={css.formFld}>
                      <FieldTextInput
                        type="email"
                        id={formId ? `${formId}.email` : 'email'}
                        name="email"
                        autoComplete="email"
                        label={intl.formatMessage({
                          id: 'SignupForm.emailLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.emailPlaceholder',
                        })}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                      />
                    </div>
                  </div>
                  <div className={classNames(css.formRow, css.fullWidth)}>
                    <div className={css.formFld}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.companyName` : 'companyName'}
                        name="companyName"
                        autoComplete="family-name"
                        label={intl.formatMessage({
                          id: 'SignupForm.companyNameLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.companyNamePlaceholder',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'SignupForm.companyNameRequired',
                          })
                        )}
                      />
                    </div>
                  </div>
                  <div className={classNames(css.formRow, css.fullWidth)}>
                    <div className={css.formFld}>
                      <FieldLocationAutocompleteInput
                        inputClassName={css.locationAutocompleteInput}
                        iconClassName={css.locationAutocompleteInputIcon}
                        predictionsClassName={css.predictionsRoot}
                        validClassName={css.validLocation}
                        name="location"
                        label={intl.formatMessage({ id: 'EditListingLocationForm.city' })}
                        placeholder={intl.formatMessage({
                          id: 'EditListingLocationForm.cityPlaceholder',
                        })}
                        useDefaultPredictions={false}
                        format={identity}
                        valueFromForm={values.location}
                        validate={validators.composeValidators(
                          validators.autocompleteSearchRequired(addressRequiredMessage),
                          validators.autocompletePlaceSelected(addressNotRecognizedMessage)
                        )}
                      />
                    </div>
                  </div>
                  <div className={classNames(css.formRow, css.fullWidth)}>
                    <div className={css.formFld}>
                      <label htmlFor="phoneNumber">
                        <FormattedMessage id="ContactDetailsForm.phoneLabel" /> </label>
                      <PhoneInput
                        className={css.inputNumberBox}
                        name="phoneNumber"
                        defaultCountry="US"
                        id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                        placeholder={phonePlaceholder}
                        value={values.phoneNumber}
                        label={phoneLabel}
                        onChange={phone => {
                          setPhoneNumberState(phone);
                        }}
                        onBlur={checkphoneNumber(form)}
                      />
                    </div>
                  </div>
                  <div className={classNames(css.formRow, css.fullWidth)}>
                    <div className={css.formFld}>
                      <FieldTextInput
                        type="text"
                        id={formId ? `${formId}.websiteName` : 'websiteName'}
                        name="websiteName"
                        autoComplete="family-name"
                        label={intl.formatMessage({
                          id: 'SignupForm.websiteNameLabel',
                        })}
                        placeholder={intl.formatMessage({
                          id: 'SignupForm.websiteNamePlaceholder',
                        })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'SignupForm.websiteNameRequired',
                          })
                        )}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className={classNames(css.formRow, css.fullWidth)}>
              <div className={css.formFld}>
                <FieldTextInput
                  type="password"
                  id={formId ? `${formId}.password` : 'password'}
                  name="password"
                  autoComplete="new-password"
                  label={intl.formatMessage({
                    id: 'SignupForm.passwordLabel',
                  })}
                  placeholder={intl.formatMessage({
                    id: 'SignupForm.passwordPlaceholder',
                  })}
                  validate={passwordValidators}
                />
              </div>
            </div>
          </div>

          <div className={css.bottomWrapper}>
            {termsAndConditions}
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

SignupFormComponent.propTypes = {
  inProgress: bool,
  termsAndConditions: node.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
